import React from "react"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Social from "../components/social.js"
import CODF from "../images/CODF.jpg"
import { FaArrowAltCircleLeft } from "react-icons/fa"
import Layout from "../components/LayoutAlt.js"

export default function DietSinglePage({ data }) {
  const diet = data.strapiRecipes

  return (
    <Layout>
        <div className="sm:w-3/4 sm:ml-1/8  xs:mt-10 sm:mt-20 mb-20 xs:p-4 sm:p-0">
        <h1 className="font-playfairdisplay font-bold xs:text-4xl sm:text-5xl text-blue-dark">{diet.title}</h1>

          <Link to={`/diet/${diet.category}`}>
            <section className="font-opensans  text-white  bg-blue-dark xs:mt-10 sm:mt-20 lg:mt-5 xl:mt-10 py-2 pl-4 
                      rounded-r-xl flex items-center">
              <FaArrowAltCircleLeft size="20" className="mr-2 cursor-pointer" />
              <p className=" text-xs sm:text-base">/diet/{diet.category}/
                {diet.slug}</p>
            </section>
          </Link>

          <section>
           
             
             <div className="grid xs:grid-cols-1 xs:gap-2 sm:grid-cols-2 lg:grid-cols-3 sm:gap-8 xs:mt-10">
               <div className="sm:col-span-2 lg:col-span-1">
                 <img 
                 srcSet={diet.imgix_path}
                 src={diet.src_img} alt="Food Photography" 
                 className=" xs:w-full sm:h-64 md:h-84 lg:h-96 object-cover object-center"  />
               </div>
               <div className="text-gray-700 space-y-4">
               <div className="space-y-2 pb-4 border-b border-gray-200 col-span-1">
                  <p className="font-opensans font-bold xs:text-sm xl:text-base">Time: <span className="font-light" >{diet.duration} Mins.</span></p>
                    <p className="font-opensans font-bold xs:text-sm xl:text-base">Servings: <span className="font-light" >{diet.serving}</span></p>
                    <p className="font-opensans font-bold xs:text-sm xl:text-base">Calories: <span className="font-light " >{diet.calories} (approx.)</span></p>
               </div>
               <div className="pb-4 border-b border-gray-200 col-span-1">
               <p className="articles font-opensans xs:text-sm xl:text-base">
                <ReactMarkdown children={diet.bible_verse} />
              </p>
               </div>
                
              <div className="flex items-center font-opensans text-xs sm:text-sm text-gray-500 space-x-2">
                    <img src={diet.chef.image.formats.thumbnail.url} alt="Chef" className="w-10 h-10 rounded-full border-2" />
                    <p className="font-bold text-blue-dark ">{diet.chef.name}</p>
                    <p className="">{diet.date}</p>
                  </div>
               </div>
               <div className="grid xs:grid-cols-1 xs:gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-1 lg:gap-2 xl:grid-cols-2 xl:gap-4 xs:mt-10 sm:mt-0 xl:mt-16 lg:pl-8">
                <img src={CODF} alt="Book" className="md:w-48 lg:w-40 xl:w-48 border-4 border-gray-200" />  
                <div className="space-y-3">
                <p className="font-montserrat font-bold xs:text-center xs:text-sm xl:text-base">Want to improve your health.</p>
                <div>
                  <p className="font-montserrat font-bold xs:text-center xs:text-sm xl:text-base">Author:</p>
                  <p className="font-playfairdisplay xs:text-center xs:text-sm xl:text-base">Ellen G. White</p>
                </div>
                <a href="https://adventistbookcenter.com/counsels-on-diet-and-foods-paper.html"><p className="xs:mx-4 sm:mx-0 md:w-28 lg:w-40 xl:w-32 mt-5 2xl:mt-10 bg-orange-dark ring ring-orange-light ring-offset-2 rounded-sm text-center text-white xs:text-sm xl:text-base">Get this Book</p></a>
                 
                </div>
                
               </div>
             </div>
             <div className="text-gray-700 space-y-2">
              <p className="mt-10 font-playfairdisplay font-bold text-3xl text-blue-dark"> Nutritional Fact: </p>
              <p className="font-normal font-opensans text-base text-gray-700">{diet.desc}</p>
            </div>
          </section>

          

          <section className="grid xs:grid-cols-1 xs:gap-2 lg:grid-cols-7 lg:gap-2  text-gray-700 mt-10">
             <p className="articles bg-blue-dark text-white px-2 pb-8 lg:col-span-2  xs:text-sm xl:text-base">
               <ReactMarkdown children={diet.ingredients_list} />
             </p>
             <p className="articles lg:col-span-5 bg-gray-100 px-2 pb-8 xs:text-sm xl:text-base">
               <ReactMarkdown children={diet.directions} />
             </p>
          </section>

               <section className="mt-20">
               <Link to={`/diet/${diet.category}`}>
                    <button className="font-opensans font-bold text-white bg-orange-dark ring ring-orange-light ring-offset-2 rounded-sm py-1 px-8">
                     Back
                    </button>
                    </Link>
               </section>
         
        </div>
        <Social />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    strapiRecipes( slug: {eq: $slug} ) {
      title
      desc
      id
      chef {
        name
        slug
        image {
          formats {
            thumbnail {
              url
            }
          }
        }
      }
      date(formatString: "LL")
      duration
      calories
      ingredients
      ingredients_list
      serving
      slug
      category
      imgix_path
      src_img
      breakfast
      bible_verse
      dinner
      directions
      lunch
      salad
      snack
    }
  }
`